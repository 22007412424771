import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LsCommonService {

    constructor() {
    }

    // like in c# if you want the thread to sleep for n milliseconds, call sleep(n).then(() => {..});
    public static sleep(ms: number): Promise<void> {
        return new Promise<void>(resolve => setTimeout(() => resolve(), ms));
    }

    public static randomInt(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public static getRandomItems<T extends unknown>(array: T[], amount = 3): T[] {
      if (!array?.length) {
        return array;
      }

      const randomItems = [];

      while (randomItems.length < amount) {
        const randomIndex = Math.floor(Math.random() * array.length);
        const randomItem = array[randomIndex];

        if (!randomItems.includes(randomItem)) {
          randomItems.push(randomItem);
        }
      }

      return randomItems;
    }

    public static shuffle(a: any) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    }

    public static getCookie(name: string) {
        const ca: Array<string> = document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

}
